@import "../src-layout/variables";

#tpl_home {
    //position: relative;
    //margin-left: 20%;
    //padding: 0;

    //.menu-container {
    //    position: fixed;
    //    top: 0;
    //    left: 0;
    //    min-height: 100vh;
    //    background-color: $c-grey;
    //    width: 20%;
    //}
    .content-wrapper {
        .header {
            min-height: 600px;
            background: url("/images/vertige/home-header-bg.jpg") center no-repeat;
            background-size: cover;
            position: relative;
        }
        .zone-1 {
            padding: 70px 120px 70px;

            h1.vertige-title {
                padding-bottom: 60px;
            }
            .left .txt-green.light {
                line-height: 45px;
            }
            .right {
                padding: 50px 30px 0;
            }
        }
        .zone-2 {
            min-height: 480px;
            background: url("/images/vertige/home-poete-illustration.jpg") center no-repeat;
            background-size: cover;
            position: relative;

            .blockquote {
                footer {
                    &:before {
                        content: "" !important;
                    }
                    cite {
                        font-style: normal;
                    }
                }
            }
        }
        .zone-3 {
            position: relative;
            padding-bottom: 140px;
            .vignette {
                h3 {
                    margin-bottom: 0;
                }
            }
        }
        .zone-4 {
            padding: 70px 120px 70px;
            .left .txt-white.light {
                line-height: 45px;
            }
            .right {
                padding: 50px 30px 0;
            }
        }
        .zone-5 {
            margin: 0 -15px;
        }
        .zone-6 {
            margin: 0 -15px;

            .left {
                .address {
                    line-height: 45px;
                }
                .phone-and-mail-wrapper {
                    position: relative;
                }
                .phone-container {
                    .rounded-icon {
                        padding-top: 9px;
                        border: 4px solid $c-green;
                        border-radius: 50%;
                        font-size: 25px;
                        width: 50px;
                        height: 50px;
                        text-align: center;
                    }
                    .phone-wrapper a {
                        display: block;
                        margin-left: 20px;
                        font-size: 18px;
                        font-weight: bold;
                    }
                }
                .address-spacer {
                    padding-top: 80px;
                    width: 100%;
                    position: relative;
                    &:before {
                        content: "";
                        width: 100%;
                        height: 1px;
                        background: #dfd4d6;
                        position: absolute;
                        top: 39px;
                    }
                }
                .mail-container {
                    .rounded-icon {
                        padding-top: 9px;
                        border: 4px solid $c-green;
                        border-radius: 50%;
                        font-size: 25px;
                        width: 50px;
                        height: 50px;
                        text-align: center;
                    }
                    .mail-wrapper a {
                        display: block;
                        margin-left: 20px;
                    }
                }
            }
            .contact-container.right {
                padding-top: 140px;
                .devis-title {
                    padding-left: 5px;
                }
                .contact-intro {
                    padding-left: 5px;
                    margin-bottom: 25px;
                }
            }
        }
    }
}

@media all and (max-width: 1400px) {
    #tpl_home .content-wrapper .zone-1,
    #tpl_home .content-wrapper .zone-4{
        padding: 60px 0 60px;
    }
}

@media all and (max-width: 1139px) {
}

@media all and (max-width: 991px) {
    #tpl_home {
        margin-left: 0px;
    }
}

@media all and (max-width: 767px) {
    #tpl_home .content-wrapper .zone-1 .left .txt-green.light,
    #tpl_home .content-wrapper .zone-4 .left .txt-white.light {
        line-height: 35px;
    }
    #tpl_home .content-wrapper .header {
        min-height: 200px;
    }
    #tpl_home .content-wrapper .zone-1 h1.vertige-title {
        padding-bottom: 20px;
    }
    #tpl_home .content-wrapper .zone-1 .right {
        padding: 15px;
    }
    #tpl_home .content-wrapper .zone-2 {
        min-height: 225px;
    }
    #tpl_home .content-wrapper .zone-4 .right {
        padding: 15px;
    }
    .zone-6 .portrait {
        text-align: center;
    }
    #tpl_home .content-wrapper .zone-6 .left .address {
        text-align: center;
        line-height: 35px;
    }
    #tpl_home .content-wrapper .zone-6 .left .phone-and-mail-wrapper {
        position: relative;
    }
    #tpl_home .content-wrapper .zone-6 .left .phone-container .phone-wrapper a,
    #tpl_home .content-wrapper .zone-6 .left .mail-container .mail-wrapper a {
        margin-left: 0;
    }
    #tpl_home .content-wrapper .zone-6 .contact-container.right .devis-title {
        font-size: 28px;
        text-align: center;
    }
    #tpl_home .content-wrapper .zone-6 .contact-container.right .contact-intro {
        font-size: 13px;
        text-emphasis: center;
    }
    #tpl_home .content-wrapper .zone-6 .contact-container.right {
        padding-top: 25px;
    }
    .contact_view .btn.btn-primary {
        display: inherit;
        margin: 0 auto !important;
    }
}