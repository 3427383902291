/***** Fonts *****/

/***** Colors *****/

/***** Texts sizes *****/

/***** transition *****/

.transition-main {
  -webkit-transition: all 200ms ease-in-out;
  -moz-transition: all 200ms ease-in-out;
  -ms-transition: all 200ms ease-in-out;
  -o-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out;
}

/***** cookie ****/

#tpl_home .content-wrapper .header {
  min-height: 600px;
  background: url("/images/vertige/home-header-bg.jpg") center no-repeat;
  background-size: cover;
  position: relative;
}

#tpl_home .content-wrapper .zone-1 {
  padding: 70px 120px 70px;
}

#tpl_home .content-wrapper .zone-1 h1.vertige-title {
  padding-bottom: 60px;
}

#tpl_home .content-wrapper .zone-1 .left .txt-green.light {
  line-height: 45px;
}

#tpl_home .content-wrapper .zone-1 .right {
  padding: 50px 30px 0;
}

#tpl_home .content-wrapper .zone-2 {
  min-height: 480px;
  background: url("/images/vertige/home-poete-illustration.jpg") center no-repeat;
  background-size: cover;
  position: relative;
}

#tpl_home .content-wrapper .zone-2 .blockquote footer:before {
  content: "" !important;
}

#tpl_home .content-wrapper .zone-2 .blockquote footer cite {
  font-style: normal;
}

#tpl_home .content-wrapper .zone-3 {
  position: relative;
  padding-bottom: 140px;
}

#tpl_home .content-wrapper .zone-3 .vignette h3 {
  margin-bottom: 0;
}

#tpl_home .content-wrapper .zone-4 {
  padding: 70px 120px 70px;
}

#tpl_home .content-wrapper .zone-4 .left .txt-white.light {
  line-height: 45px;
}

#tpl_home .content-wrapper .zone-4 .right {
  padding: 50px 30px 0;
}

#tpl_home .content-wrapper .zone-5 {
  margin: 0 -15px;
}

#tpl_home .content-wrapper .zone-6 {
  margin: 0 -15px;
}

#tpl_home .content-wrapper .zone-6 .left .address {
  line-height: 45px;
}

#tpl_home .content-wrapper .zone-6 .left .phone-and-mail-wrapper {
  position: relative;
}

#tpl_home .content-wrapper .zone-6 .left .phone-container .rounded-icon {
  padding-top: 9px;
  border: 4px solid #9dc82d;
  border-radius: 50%;
  font-size: 25px;
  width: 50px;
  height: 50px;
  text-align: center;
}

#tpl_home .content-wrapper .zone-6 .left .phone-container .phone-wrapper a {
  display: block;
  margin-left: 20px;
  font-size: 18px;
  font-weight: bold;
}

#tpl_home .content-wrapper .zone-6 .left .address-spacer {
  padding-top: 80px;
  width: 100%;
  position: relative;
}

#tpl_home .content-wrapper .zone-6 .left .address-spacer:before {
  content: "";
  width: 100%;
  height: 1px;
  background: #dfd4d6;
  position: absolute;
  top: 39px;
}

#tpl_home .content-wrapper .zone-6 .left .mail-container .rounded-icon {
  padding-top: 9px;
  border: 4px solid #9dc82d;
  border-radius: 50%;
  font-size: 25px;
  width: 50px;
  height: 50px;
  text-align: center;
}

#tpl_home .content-wrapper .zone-6 .left .mail-container .mail-wrapper a {
  display: block;
  margin-left: 20px;
}

#tpl_home .content-wrapper .zone-6 .contact-container.right {
  padding-top: 140px;
}

#tpl_home .content-wrapper .zone-6 .contact-container.right .devis-title {
  padding-left: 5px;
}

#tpl_home .content-wrapper .zone-6 .contact-container.right .contact-intro {
  padding-left: 5px;
  margin-bottom: 25px;
}

@media all and (max-width: 1400px) {
  #tpl_home .content-wrapper .zone-1,
  #tpl_home .content-wrapper .zone-4 {
    padding: 60px 0 60px;
  }
}

@media all and (max-width: 991px) {
  #tpl_home {
    margin-left: 0px;
  }
}

@media all and (max-width: 767px) {
  #tpl_home .content-wrapper .zone-1 .left .txt-green.light,
  #tpl_home .content-wrapper .zone-4 .left .txt-white.light {
    line-height: 35px;
  }

  #tpl_home .content-wrapper .header {
    min-height: 200px;
  }

  #tpl_home .content-wrapper .zone-1 h1.vertige-title {
    padding-bottom: 20px;
  }

  #tpl_home .content-wrapper .zone-1 .right {
    padding: 15px;
  }

  #tpl_home .content-wrapper .zone-2 {
    min-height: 225px;
  }

  #tpl_home .content-wrapper .zone-4 .right {
    padding: 15px;
  }

  .zone-6 .portrait {
    text-align: center;
  }

  #tpl_home .content-wrapper .zone-6 .left .address {
    text-align: center;
    line-height: 35px;
  }

  #tpl_home .content-wrapper .zone-6 .left .phone-and-mail-wrapper {
    position: relative;
  }

  #tpl_home .content-wrapper .zone-6 .left .phone-container .phone-wrapper a,
  #tpl_home .content-wrapper .zone-6 .left .mail-container .mail-wrapper a {
    margin-left: 0;
  }

  #tpl_home .content-wrapper .zone-6 .contact-container.right .devis-title {
    font-size: 28px;
    text-align: center;
  }

  #tpl_home .content-wrapper .zone-6 .contact-container.right .contact-intro {
    font-size: 13px;
    text-emphasis: center;
  }

  #tpl_home .content-wrapper .zone-6 .contact-container.right {
    padding-top: 25px;
  }

  .contact_view .btn.btn-primary {
    display: inherit;
    margin: 0 auto !important;
  }
}

