/***** Fonts *****/

$font-main: 'Open Sans', sans-serif;
$font-secondary: 'Lato', sans-serif;

/***** Colors *****/

$c-green: #9dc82d;
$c-grey: #858585;
$c-white: #fff;

$color-text-primary: $c-grey;
$color-text-main: $c-grey;

$color-main: #ffffff;
$color-secondary: $c-green;
$color-third: #474747;
$color-four: #00b1eb;
$color-five: #84d0f5;


/***** Texts sizes *****/

$text-size-main: 17px;
$text-size-small: 14px;
$text-size-intro: 25px;
$text-size-big: 25px;
$text-size-h1: 50px;
$text-size-h2: 30px;
$text-size-h3: 18px;


/***** transition *****/
.transition-main{
  -webkit-transition: all 200ms ease-in-out;
  -moz-transition: all 200ms ease-in-out;
  -ms-transition: all 200ms ease-in-out;
  -o-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out;
}


/***** cookie ****/
$bandeau-cookie-bg: rgba(200, 200, 200, 0.9);
$bandeau-cookie-txt: #000000;
$bandeau-cookie-bouton-bg: #008300;
$bandeau-cookie-bouton-txt: #ffffff;